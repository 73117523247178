import styled, { css } from "styled-components";
import { breakpoints } from "./size";

import arrow from "../images/ic_arrow_right.svg";
import arrowBlue from "../images/arrow_blue.svg";

// Desktop navigation
export const NavigationDesktopWrapper = styled.div`
  border: 1px solid ${(props) => props.theme.borderColor};
  box-shadow: 3px 2px 30px rgba(68, 68, 68, 0.12);
  opacity: 1;
  // background-color: rgba(0, 0, 0, 0.4);
  width: 293px;
  position: relative;

  ${(props) =>
    props.hide &&
    css`
      width: auto;
    `};
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: -webkit-fill-available;
  padding: 75px 30px 40px;
  background: ${(props) => props.theme.sidebarNew};

  ${(props) =>
    props.hide &&
    css`
      padding: 75px 20px 40px;
    `};
`;

export const TopContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 50px;

  .smallLogo {
    width: 44px;
  }

  .logo {
    width: 122px;
  }
`;

export const MenuWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;

  a {
    opacity: 1;
    color: ${(props) => props.theme.blueish4};
    font-family: "SatoshiMedium";
    font-size: 16px;
    font-weight: 500;
    font-style: Medium;
    letter-spacing: 0.5px;
    text-align: left;
    line-height: 24px;
    text-decoration: none;
    padding: 6px 13px;
    height: 40px;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    white-space: nowrap;
    position: relative;

    &.active {
      background-color: ${(props) => props.theme.green2};
      flor: right;
      text-align: right;

      &:after {
        content: url(${localStorage.getItem("theme") === "light"
          ? arrowBlue
          : arrow});
        position: absolute;
        right: 10px;
      }
    }

    &:hover {
      color: ${(props) => props.theme.green};
    }
  }
`;

export const BottomContent = styled.div``;

export const ResizeMenu = styled.div`
  position: absolute;
  border: 1px solid ${(props) => props.theme.lines};
  box-shadow: 0px 1px 5px ${(props) => props.theme.green};
  opacity: 1;
  background-color: ${(props) => props.theme.black};
  width: 44px;
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  right: -22px;
  bottom: 15%;
  cursor: pointer;
`;

// Mobile navigation
export const NavigationMobileWrapper = styled.div`
  border: 1px solid rgba(24, 25, 24, 1);
  box-shadow: 0px 2px 30px rgba(68, 68, 68, 0.12);
  opacity: 1;
  background-color: ${(props) => props.theme.black};
  z-index: 2;
`;

export const MobileContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;

  .mobileLogo {
    width: 106px;
  }
`;

export const MobileListOverylay = styled.div`
  background-color: ${(props) => props.theme.sidebar};
  height: 100vh;
  width: 100%;
  backdrop-filter: blur(5px);
  position: absolute;
  z-index: 1;
`;

export const MobileList = styled.div`
  position: absolute;
  top: 80px;
  width: -webkit-fill-available;
  height: fit-content;

  border: 1px solid rgba(24, 25, 24, 1);
  box-shadow: 0px 4px 40px ${(props) => props.theme.green};
  opacity: 1;
  background-color: ${(props) => props.theme.sidebar};
`;

export const ContentMenu = styled.div`
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 0;

  a {
    opacity: 1;
    color: ${(props) => props.theme.blueish};
    font-family: "SatoshiMedium";
    font-size: 16px;
    font-weight: 500;
    font-style: Medium;
    letter-spacing: 0.5px;
    text-align: left;
    line-height: 24px;
    text-decoration: none;
    padding: 6px 13px;
    height: 40px;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    position: relative;

    &.active {
      flor: right;
      text-align: right;
      color: ${(props) => props.theme.green};
    }

    &:hover {
      color: ${(props) => props.theme.green};
    }
  }
`;
